export default class Countdown {
  constructor(targetDate, onCountdownEnd) {
    this.targetDate = targetDate;
    this.interval = null;
    this.onCountdownEnd = onCountdownEnd;
    this.update();
    this.start();
  }

  update() {
    const now = new Date().getTime();
    this.distance = this.targetDate - now;
  }

  start() {
    this.interval = setInterval(() => {
      this.update();
      if (this.distance < 0) {
        this.stop();
        if (typeof this.onCountdownEnd === 'function') {
          this.onCountdownEnd();
        }
      }
    }, 1000);
  }

  stop() {
    clearInterval(this.interval);
  }

}