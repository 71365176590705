import io from 'socket.io-client';
import wrtc from 'wrtc';
import QRCode from 'qrcode';
import SimpleSignalClient from 'simple-signal-client';
import messagesEmitter from './messagesEmitters';
import {logger} from "./logger";

const createSignalSocket = (domain) => {
  const socket = io(domain, {
    transports: ["websocket"],
    timeout: 5000,
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });
  logger.info('createSignalSocket')
  return socket;
};

const initSignalServer = (ctx, setQrUrl, setQrScanned, params, done) => {

  ctx.socket = createSignalSocket(params.domain);

  ctx.signalClient = new SimpleSignalClient(ctx.socket);

  ctx.socket.on("error", (err) => {
    ctx.error = err;
    messagesEmitter.emit("error", new Error(`Failed to Socket error " + ${err.message}`));
  });

  ctx.socket.on("connect_error", (err) => {
    ctx.error = err;
    messagesEmitter.emit("error", new Error(`Failed to Socket connect error " + ${err.message}`));
  });

  ctx.socket.on("disconnect", (reason) => {
    ctx.error = `Socket disconnected for reason ${reason}`;
    if (reason === "transport close" || reason === "io server disconnect") {
      messagesEmitter.emit("error", new Error(`Socket disconnected for transport close`));
    }
    else {
      messagesEmitter.emit("error", new Error(`Socket disconnected for unhandled reason ${reason}`));
    }
  });

  ctx.signalClient.on("request", async (request) => {
    logger.info("Request received");

    if (params.clientIdFix && params.onlyOneConnection) {
      // If there's an existing connection
      if (ctx.remotePeer) {
          messagesEmitter.emit("newUserConnection");
      }
    }

    const { peer } = await request.accept({}, { wrtc });
    ctx.remotePeer = peer;
    setQrScanned(true);

    const rtcPeerConnection = ctx.remotePeer._pc;

    // Detect closing connection
    if (rtcPeerConnection) {
      rtcPeerConnection.oniceconnectionstatechange = () => {
        logger.warn("ICE connection state: " + rtcPeerConnection.iceConnectionState);
        if (rtcPeerConnection.iceConnectionState == "disconnected") {
          messagesEmitter.emit("error", new Error(`WebRTC connection disconnected`));
        }
      };
    }

    logger.info("peer accepted, sending coucou");
    done();
  });

  ctx.signalClient.on("discover", function () {
    logger.info(`id ${ctx.signalClient.id}`);
    const url = `${params.domain}/game/${ctx.signalClient.id}/${params.scenario}`;
    logger.info(url);
    QRCode.toDataURL(url)
      .then((data) => {
        setQrUrl(data);
      })
      .catch((error) => {
        logger.error(error);
      });
  });
  let clientIdFixValue = params.clientIdFixValue;
  if (params.clientIdFix) {
      ctx.signalClient.discover({ clientIdFixValue });
  }
  else {
      ctx.signalClient.discover();
  }
};

export default initSignalServer;
