import React, { useState, useEffect } from "react";
import axios from 'axios';

const InitBackconn = (setIsReady, setLandingVideoURL, setQRSize, setLeftOffest, setTopOffset, setMedias, setObject3D, setScenario, ScenarioUUID) => {

//const backendconfig = require ("../BackendConfig.json")
const backendconfig = {domain: window.location.origin + window.location.pathname};


const url = `${backendconfig.domain}backend/?scenario_uuid=${ScenarioUUID}`;

fetch(url)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    console.log(data);

    if(data.object_3d_url === ""){
      setObject3D(null);
    }else{
      setObject3D(`${backendconfig.domain}backend/uploads/${data.object_3d_url}`);
    }

    setLandingVideoURL(`${backendconfig.domain}backend/uploads/${data.intro_video_url}`);
    setQRSize(data.qrcode_scale);
    setLeftOffest(data.qrcode_xpos);
    setTopOffset(data.qrcode_ypos);
    setMedias(data.Media);
    setScenario(data.scenario_aos_id);

    setIsReady(true);
  })
  .catch(error => {
    console.error('There has been a problem with your fetch operation:', error);
  });

    

}


export default InitBackconn;