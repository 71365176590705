import messagesEmitter from './messagesEmitters.js';
import {logger} from "./logger";

const initRemote = (ctx, firstPage, done) => {
  ctx.remotePeer.on("data", (data) => {
    const dataString = Buffer.from(data).toString().replace("#", "");
    try {
      const dataJson = JSON.parse(dataString);
      if (dataJson.cmd === "HELO") {
        ctx.remotePeer.send(
          Buffer.from(
            JSON.stringify({
              cmd: "OK",
            })
          )
        );
        ctx.remotePeer.removeAllListeners("data");
        logger.info("Init Remote done");
        initRemotePage(ctx, firstPage);
        done();
      }
    } catch (error) {
      ctx.error = error;
      messagesEmitter.emit("error", error);
    }
  });

  ctx.remotePeer.on("close", (err) => {
    if (!err) {
      // strValue was empty string, false, 0, null, undefined, ...
      err = "close";
      messagesEmitter.emit("error", new Error(`WebRTC connection disconnected`));
    }
    ctx.error = err;
    messagesEmitter.emit("error", err);
  });

  ctx.remotePeer.on("error", (err) => {
      ctx.error = err;
      messagesEmitter.emit("error", err);
  });
};

const initRemotePage = (ctx, firstPage) => {
  if (!ctx.remotePeer.destroyed) {
    const str = `${JSON.stringify({
      cmd: "CHANGE_PAGE",
      args: {
        id: firstPage,
      },
    })}#`;
    ctx.remotePeer.send(Buffer.from(str));
  } else {
    logger.error("Cannot change remote first page, remotePeer is destroyed");
  }
};

export default initRemote;
