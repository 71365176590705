import React, {useEffect, useState} from 'react';
import QRCode from "react-qr-code";
import './Landing.css';

const Landing = (props) => {
  const { qrUrl ,LandingVideoURL, leftOffset, topOffset, QRSize } = props;

  const [displayQR, setDisplayQR] = useState("none")

  useEffect(() => {
    if(qrUrl === null){
        setDisplayQR("none")
    }else{
        setDisplayQR("block")
    }
  }, [qrUrl]);


  return (
    <div id="video-wrapper">
      <div id="landing-container">
        <video autoPlay muted loop src={LandingVideoURL} id="video-content"></video>
        <div id="overlays">
          <div
            id="QRCODE"
            style={{
              left: `${leftOffset}%`,
              top: `${topOffset}%`,
              width: `${QRSize}%`,
              display: displayQR,
            }}
          >
            <img src={qrUrl}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
