import messagesEmitter from "../messagesEmitters";
import {logger} from "../logger";

let connectionName;
let connectionJson = {
    startTime: 0,
    endTime: 0,
    deconnectionReason: ""
}

class DatabaseConnections {
  constructor() {
  	  messagesEmitter.on("startConnection", (connectionWebRTC) => {
      	connectionJson.startTime = new Date().getTime();
      	connectionName = connectionWebRTC;
      	//reset 
      	connectionJson.deconnectionReason = "";
  	  });
  	  messagesEmitter.on("endConnection", (reason) => {
    		connectionJson.endTime = new Date().getTime();
    		connectionJson.deconnectionReason = reason;
    		logger.writeConnection(connectionJson, connectionName);
  	  });
  }
}

export default DatabaseConnections;