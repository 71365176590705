import React, { useRef, useState, useEffect, Suspense } from "react";
import { Canvas, useFrame, useThree} from "@react-three/fiber";
import { useGLTF, Environment} from "@react-three/drei";
import { Euler, Box3, Vector3 } from "three";
import * as THREE from 'three';
import messagesEmitter from "../webAos/handlers/messagesEmitters";
import "./Experience3D.css";

const Loader = () => {
  return <div style={{color:"white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "24px" ,fontWeight: "bold" }}>Chargement...</div>;
};

const Model = ({ url, rotation, referenceSize = 0.05 }) => {
  const gltf = useGLTF(url);
  const materials = useGLTF(url);
  const ref = useRef();
  const scale = useRef([1, 1, 1]);
  const targetRotation = useRef(new Euler(0, 0, 0)).current;
  const rotationSpeed = 0.10;
  const dampingFactor = 0.10;

  useEffect(() => {
    if (materials) {
      Object.values(materials).forEach(material => {
        if (material.map) {
          material.map.anisotropy = 16; // Améliore la qualité de la texture à des angles obliques
          material.map.minFilter = THREE.LinearMipmapLinearFilter; // Ajuste le filtrage
          // Autres ajustements de matériel et de texture selon les besoins
        }
      });
    }
  }, [materials]);

  useEffect(() => {
    // Calcul de l'échelle une seule fois après le chargement du modèle
    if (ref.current) {
      const box = new Box3().setFromObject(ref.current);
      const size = new Vector3();
      box.getSize(size);
      const maxDimension = Math.max(size.x, size.y, size.z);
      const scaleFactor = referenceSize / maxDimension;
      scale.current = [scaleFactor, scaleFactor, scaleFactor];
      ref.current.scale.set(...scale.current);
    }
  }, [url, referenceSize]);

  useFrame(() => {
    if (ref.current) {

      targetRotation.set(rotation.x, rotation.y, rotation.z);
      const diffX = targetRotation.x - ref.current.rotation.x;
      const diffY = targetRotation.y - ref.current.rotation.y;
      const diffZ = targetRotation.z - ref.current.rotation.z;
      const diffMagnitude = Math.sqrt(diffX ** 2 + diffY ** 2 + diffZ ** 2);
      const adjustedSpeed = Math.min(rotationSpeed + dampingFactor * diffMagnitude, 1);

      // Appliquer la rotation ajustée
      ref.current.rotation.x += diffX * adjustedSpeed;
      ref.current.rotation.y += diffY * adjustedSpeed;
      ref.current.rotation.z += diffZ * adjustedSpeed;
    }
  });

  return <primitive object={gltf.scene} ref={ref} />;
};

const CameraPositioner = () => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 5);
    camera.lookAt(0, 0, 0);
    camera.fov = 1;
    camera.updateProjectionMatrix();
  }, [camera]);

  return null;
};

const CameraControls = () => {
  const { camera } = useThree();

  useEffect(() => {
    const handleWheel = (event) => {
      const minFOV = 0.1;
      const maxFOV = 1;
      const zoomLevel = event.zoom / 100;
      camera.fov = maxFOV - ((maxFOV - minFOV) * zoomLevel);
      camera.fov = Math.max(minFOV, Math.min(maxFOV, camera.fov));
      camera.updateProjectionMatrix();
    };

    messagesEmitter.on("zoom", handleWheel);
    //return () => messagesEmitter.off("zoom", handleWheel);
  }, [camera]);

  return null;
};

const Experience3D = ({ object3D }) => {
  const [rotation, setRotation] = useState(new Euler(0, 0, 0));



  useEffect(() => {
    const handleKeyDown = (event) => {
      setRotation((currentRotation) => {
        const newRotation = new Euler(currentRotation.x, currentRotation.y, currentRotation.z);
        newRotation.x += 0.1 * event.my / 400;
        newRotation.y += 0.1 * event.mx / 400;
        return newRotation;
      });
    };

    messagesEmitter.on("move", handleKeyDown);
    //return () => messagesEmitter.off("move", handleKeyDown);

  }, []);




  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <Suspense fallback={<Loader />}>
        <Canvas>
        <Environment files="city.hdr" background />
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 10, 5]} intensity={1} />
          <CameraPositioner />
          <CameraControls />
          <Model url={object3D} rotation={rotation} />
        </Canvas>
      </Suspense>
    </div>
  );
};

export default Experience3D;
