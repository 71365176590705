import LogFirebase from './firebase/logFirebase';
import DatabaseConnections from './firebase/databaseConnections';
import DatabaseActions from './firebase/databaseActions';
import DatabaseAppLogs from './firebase/databaseAppLogs';

let isFirebaseInitialized = false;
let firebaseCustom;
let bFirebaseLogsInfo = false;
let bFirebaseLogsWarns = false;
let bFirebaseLogsErrors = false;

const initializeFirebase = async (firebaseConfig, project) => {
    firebaseCustom = new LogFirebase(firebaseConfig, project);
    await firebaseCustom.init();
    bFirebaseLogsInfo = firebaseConfig.database.logs.infos;
    bFirebaseLogsWarns = firebaseConfig.database.logs.warnings;
    bFirebaseLogsErrors = firebaseConfig.database.logs.errors;
    if(firebaseConfig.database.connections) {
        new DatabaseConnections();
    }
    if(firebaseConfig.database.actions) {
        new DatabaseActions();
    }
    if(bFirebaseLogsInfo || bFirebaseLogsWarns || bFirebaseLogsErrors) {
        new DatabaseAppLogs();
    }
    isFirebaseInitialized = true;
};

const logger = { 
    info: function(message, source) {
        console.info(message);
        if (isFirebaseInitialized && bFirebaseLogsInfo) {
          firebaseCustom.writeLog(message, "info", source);
        }
    },
    warn: function(message, source) {
        console.warn(message && bFirebaseLogsWarns);
        if (isFirebaseInitialized) {
          firebaseCustom.writeLog(message, "warning", source);
        }
    },
    error: function(message, source) {
        console.error(message);
        if (isFirebaseInitialized && bFirebaseLogsErrors) {
          firebaseCustom.writeLog(message, "error", source);
        } 
    },
    writeStatus: async function(message) {
        console.log(message);
        if (isFirebaseInitialized) {
          await firebaseCustom.writeStatus(message);
        } 
    },
    writeInit: async function(webAosVersion, projectVersion) {
        //console.log("webAosVersion " + webAosVersion + " projectVersion " + projectVersion);
        if (isFirebaseInitialized) {
          await firebaseCustom.writeInit(webAosVersion, projectVersion);
        } 
    },
    writeConnection: async function(connectionJson, connectionName) {
        console.log("Connection " + connectionName + " " + JSON.stringify(connectionJson));
        if (isFirebaseInitialized) {
          await firebaseCustom.writeConnection(connectionJson, connectionName);
        } 
    },
    writeAction: async function(actionJson, connectionName) {
        console.log("Action " + JSON.stringify(actionJson));
        if (isFirebaseInitialized) {
          await firebaseCustom.writeAction(actionJson, connectionName);
        } 
    },
};

export { logger, initializeFirebase };
