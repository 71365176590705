class Context {
  constructor() {
    this.remotePeer = null;
    this.signalClient = null;
    this.socket = null;
    this.game = null;
    this.error = null;
  }

  clearContext(setQrUrl, setQrScanned, setWebRTCReady) {
    console.log("clearContext...");
    setQrUrl(null);
    setQrScanned(false);
    setWebRTCReady(false);
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.close();
      this.socket = null;
    }

    if (this.signalClient) {
      this.signalClient.removeAllListeners();
      this.signalClient.socket.close();
      this.signalClient = null;
    }

    if (this.game) {
      this.game.removeAllListeners();
      this.game.destroy();
    }

    // Clear remote peer sinon double listener
    this.cleanRemotePeer();
  }

  clearContextUser(setWebRTCReady) {
    console.log("clearContextUser...");
    setWebRTCReady(false);
    if (this.remotePeer) {
      this.remotePeer.removeAllListeners();
      this.remotePeer.destroy();
    }

    if (this.game) {
      this.game.removeAllListeners();
      this.game.destroy();
      this.game = null;
    }
    this.cleanRemotePeer();
  }

  cleanRemotePeer() {
    if (this.remotePeer) {
      this.remotePeer.removeAllListeners();
      // Detach existing event listeners to avoid multiple listeners over time
      if (this.remotePeer._pc) {
        this.remotePeer._pc.oniceconnectionstatechange = null;
      }
      // Destroy old connection
      this.remotePeer.destroy();
      this.remotePeer = null;
    }
  }
}

export default Context;
