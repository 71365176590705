import messagesEmitter from "../messagesEmitters";
import {logger} from "../logger";


class DatabaseAppLogs {
  constructor() {
	  messagesEmitter.on("logDatabase", (message, logType) => {
      switch (logType)
      {
          default:
          case "info":
              logger.info(message, "appJS");  
              break;
          case "warning":
              logger.warn(message, "appJS");  
              break;
          case "error":
              logger.error(message, "appJS");  
              break;
      }   
	  });
  }
}
export default DatabaseAppLogs;