import messagesEmitter from './messagesEmitters.js';
import {logger} from "./logger";
import Countdown from './countdown';

const game = (ctx, setWebRTCReady, timeOutConnection, done) => {
  removeGameListeners();
  messagesEmitter.emit("startConnection", ctx.signalClient.id);
  addTimeout(timeOutConnection);
  ctx.remotePeer.on("data", (data) => {
    const cmdsFromData = data.toString().split("#");
    cmdsFromData
      .filter((i) => i !== "")
      .map((cmd) => JSON.parse(cmd))
      .forEach((jsonData) => {
        routeMessage(jsonData, ctx);
      });
  });
  setWebRTCReady(true);
  addListeners(ctx);
};

const addListeners = (ctx) => {
  messagesEmitter.on("change_remote", (pageId, pageVariant = null) => {
    changeRemotePage(pageId, ctx, pageVariant);
  });
  messagesEmitter.on("set_zoom", (value) => {
    setZoom(value, ctx);
  });
  messagesEmitter.on("set_button_multichoice", (stringParam, arrayOfObjsParam) => {
    setButtonMultichoice(stringParam, arrayOfObjsParam, ctx);
  });
  messagesEmitter.on("set_close_modal", (arrayOfObjsParam) => {
    setCloseModal(arrayOfObjsParam, ctx);
  });
  messagesEmitter.on("set_poweron", (arrayOfObjsParam) => {
    setPowerOn(arrayOfObjsParam, ctx);
  });
  messagesEmitter.on("set_switch", (idBt, checked, disabled = false) => {
    setSwitch(idBt, checked, disabled, ctx);
  });
  messagesEmitter.on("set_text", (textID, label) => {
    setText(textID, label, ctx);
  });
  messagesEmitter.on("set_component_visibility", (componentId, visible) => {
    setComponentVisibility(componentId, visible, ctx);
  });
  messagesEmitter.on("set_axis", (stringParam) => {
    setAxis(stringParam, ctx);
  });
};

const addTimeout = (timeOutConnection) => {
  if(timeOutConnection != 0) {
    const targetDate = new Date().getTime() + timeOutConnection * 1000;
    const onCountdownEnd = () => {
      messagesEmitter.emit("error", "Client Timeout");
    };
    new Countdown(targetDate, onCountdownEnd);
  }
}

const removeGameListeners = () => {
  messagesEmitter.removeAllListeners("change_remote");
  messagesEmitter.removeAllListeners("set_zoom");
  messagesEmitter.removeAllListeners("set_button_multichoice");
  messagesEmitter.removeAllListeners("set_close_modal");
  messagesEmitter.removeAllListeners("set_poweron");
  messagesEmitter.removeAllListeners("set_switch");
  messagesEmitter.removeAllListeners("set_text");
  messagesEmitter.removeAllListeners("set_component_visibility");
  messagesEmitter.removeAllListeners("set_axis");
};

const routeMessage = (json, ctx) => {
  //logger.info(json);
  messagesEmitter.emit("remoteAction", json, ctx.signalClient.id);
  switch (json.cmd) {
    case "HEARTBEAT":
      // emit a 'heartbeat' event
      messagesEmitter.emit("heartbeat");
      break;
    case "CLOSE":
      closeClient();
      break;
    case "MOVE":
      // emit a 'move' event
      messagesEmitter.emit("move", json.args);
      break;
    case "ZOOM":
      // emit a 'zoom' event
      messagesEmitter.emit("zoom", json.args);
      break;
    case "BTN_PRESSED":
      // emit a 'btn_pressed' event
      messagesEmitter.emit("btn_pressed", json.args);
      break;
    case "SET_CHOICES":
      // emit a 'set_choices' event
      messagesEmitter.emit("set_choices", json.args);
      break;
    case "SET_MULTICHOICE":
      // emit a 'set_multichoice' event
      messagesEmitter.emit("set_multichoice", json.args);
      break;
    case "POWER":
      // emit a 'power' event
      messagesEmitter.emit("power");
      break;
  }
};

const changeRemotePage = (page, ctx, pageVariant = null) => {
  const str = `${JSON.stringify({
    cmd: "CHANGE_PAGE",
    args: {
      id: page,
      variant: pageVariant,
    },
  })}#`;
  sendPeer(str, ctx);
};

const closeClient = () => {
  const errorMessage = "Client closed by user";
  messagesEmitter.emit("error", errorMessage);
};

const setZoom = (value, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_ZOOM",
    args: {
      zoom: value,
    },
  })}#`;
  sendPeer(str, ctx);
};

const setButtonMultichoice = (stringParam, arrayOfObjsParam, ctx) => {
  const params = [
    {
      key: stringParam,
      value: arrayOfObjsParam,
    },
  ];
  const str = `${JSON.stringify({
    cmd: "SET_BUTTON_MULTICHOICE",
    args: params,
  })}#`;
  sendPeer(str, ctx);
};

const setCloseModal = (arrayOfObjsParam, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_CLOSE_MODAL",
    args: arrayOfObjsParam,
  })}#`;
  sendPeer(str, ctx);
};

const setPowerOn = (arrayOfObjsParam, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_POWERON",
    args: arrayOfObjsParam,
  })}#`;
  sendPeer(str, ctx);
};

const setSwitch = (value, checked, disabled, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_SWITCH",
    args: {
      IdBt: value,
      checked: checked,
      disabled: disabled,
    },
  })}#`;
  sendPeer(str, ctx);
};

const setText = (textID, label, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_TEXT",
    args: {
      textID,
      label,
    },
  })}#`;
  sendPeer(str, ctx);
};

const setComponentVisibility = (componentId, visible, ctx) => {
  const str = `${JSON.stringify({
    cmd: "SET_COMPONENT_VISIBILITY",
    args: {
      componentId,
      visible,
    },
  })}#`;
  sendPeer(str, ctx);
};

const setAxis = (stringParam, ctx) => {
  const str = `${JSON.stringify({
    cmd: "AXIS_CFG",
    args: {
      config: stringParam,
    },
  })}#`;
  sendPeer(str, ctx);
};

const sendPeer = (stringParam, ctx) => {
  // Check if remotePeer exists and is still active
  if (ctx.remotePeer && !ctx.remotePeer.destroyed) {
    logger.info(`Send message from webAos ${stringParam} redirecting to remote`);
    ctx.remotePeer.send(Buffer.from(stringParam));
  } else {
    logger.error(`Cannot send data ${stringParam} , remotePeer is either null or destroyed`);
  }
};

export default game;
