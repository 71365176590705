import Context from "./Context";
import util from "util";
import initRemote from "./handlers/remote";
import initSignalServer from "./handlers/signaling";
import game from "./handlers/game";
import messagesEmitter from "./handlers/messagesEmitters";
import {logger, initializeFirebase} from "./handlers/logger";

const initSignalServerPromise = util.promisify(initSignalServer);
const initRemotePromise = util.promisify(initRemote);
const gamePromise = util.promisify(game);

const pjson = require('./package.json');
let contextState = "";

const run = async (ctx, setQrUrl, setQrScanned, setWebRTCReady, webAosConfigJson) => {
  try {
    ctx.clearContext(setQrUrl, setQrScanned, setWebRTCReady);
    if (contextState != "NO_QRCODE"){
      await logger.writeStatus("NO_QRCODE");
      contextState = "NO_QRCODE";
    }
    logger.info("=== [INIT SIGNALING] ==="); // QRCODE
    if (contextState != "QRCODE"){
      await logger.writeStatus("QRCODE");
      contextState = "QRCODE";
    }
    await initSignalServerPromise(ctx, setQrUrl, setQrScanned, webAosConfigJson);
    logger.info("=== [INIT REMOTE] ===");
    await initRemotePromise(ctx, webAosConfigJson.firstPage);
    logger.info("=== [INIT GAME] ==="); // USER_CONNECTED
    if (contextState != "USER_CONNECTED"){
      await logger.writeStatus("USER_CONNECTED");
      contextState = "USER_CONNECTED";
    }
    ctx.game = await gamePromise(ctx, setWebRTCReady, webAosConfigJson.timeOutConnection);
  } catch (err) {
    messagesEmitter.emit("error", err);
  } finally {
    ctx.clearContext(setQrUrl, setQrScanned, setWebRTCReady);
  }
};

const main = async (setQrUrl, setQrScanned, setWebRTCReady, webAosConfigJson, firebaseConfig) => {
  const ctx = new Context();
  if (firebaseConfig !== null && firebaseConfig !== undefined) {
    await initializeFirebase(firebaseConfig, webAosConfigJson.projectName);
  }
  await logger.writeInit(pjson.version, webAosConfigJson.projectVersion);

  messagesEmitter.on("error", (err) => {
    let errMessage;
    if (err.message) {
      errMessage = err.message;
    } else {
      errMessage = err.toString(); // Convert the error object to string
    }

    const warn1 = "Not available scenarios received after timeout, restarting.";
    const warn2 = "Unity closed socket";
    const warn3 = "Socket disconnected for reason io client disconnect";
    const warn5 = "Socket disconnected for reason transport close";
    const warn6 = "Error: Ice connection failed.";
    if (errMessage === warn1 || errMessage === warn2 || errMessage === warn3 || errMessage === warn5 || errMessage === warn6)
    {
      logger.warn(errMessage);
    }  
    else
    {
      logger.error(errMessage);
    }

    // TODO: a tester si il faut le garder
    if (errMessage !== "close") {
      logger.info("webAOS restart");
      messagesEmitter.emit("endConnection", errMessage);
      run(ctx, setQrUrl, setQrScanned, setWebRTCReady, webAosConfigJson);
    }
  });

  messagesEmitter.on("newUserConnection", async() => {
    try {
      logger.info("New User Detected");
      ctx.clearContextUser(setWebRTCReady);
      logger.info("=== [INIT REMOTE] ===");
      await initRemotePromise(ctx, webAosConfigJson.firstPage);
      logger.info("=== [INIT GAME] ==="); // USER_CONNECTED
      if (contextState != "USER_CONNECTED"){
        await logger.writeStatus("USER_CONNECTED");
        contextState = "USER_CONNECTED";
      }
      ctx.game = await gamePromise(ctx, setWebRTCReady, webAosConfigJson.timeOutConnection);
    }
    catch (err) {
      messagesEmitter.emit("error", err);
    }
  });


  try {
    logger.info(`=== START === DOMAIN = ${webAosConfigJson.domain}, VERSION = ${pjson.version}`);

    await run(ctx, setQrUrl, setQrScanned, setWebRTCReady, webAosConfigJson);
  } catch (err) {
    messagesEmitter.emit("error", err);
  }
};

const webAos = {
  start: (setQrUrl, setQrScanned, setWebRTCReady, webAosConfig, firebaseConfig) => {
    main(setQrUrl, setQrScanned, setWebRTCReady, webAosConfig, firebaseConfig);
  },
};

export default webAos;
