import React, { useState, useEffect } from "react";
import webAos from "./webAos/main.js";
import "./App.css"
import InitBackconn from "./Logics/backconn.js";
import Landing from "./Pages/Landing.js"
import Experiences from "./Pages/Experience.js"
import Experience3D from "./Pages/Experience3D.js"
import messagesEmitter from "./webAos/handlers/messagesEmitters.js";

//AOS intégration
//const firebaseJson = require("./webAos/firebaseConfig.json");
var aosJsonFile = require ("./webAos/aosConfig.json");

const App = () => {
  //States
  const [qrUrl, setQrUrl] = useState(null);
  const [qrScanned, setQrScanned] = useState(false);
  const [webRTCReady, setWebRTCReady] = useState(false);
  const [PageToDisplay, setPageToDisplay] = useState("landing");
  const [LandingVideoURL, setLandingVideoURL] = useState("");
  const [IsReady, setIsReady] = useState(false);
  const [QRSize, setQRSize] = useState(0);
  const [Medias, setMedias] = useState([])
  const [leftOffset, setLeftOffest] = useState(0);
  const [topOffset, setTopOffset] = useState(0);
  const [isNavigationEnabled, setIsNavigationEnabled] = useState(false)
  const [object3D, setObject3D] = useState(null);
  const [scenario, setScenario] = useState("");
  const [countdown, setCountdown] = useState(null);

// AOS intégration
  const pjson = require('./../package.json');

  useEffect(() => {
    if(scenario !== undefined){
  aosJsonFile.scenario = scenario;
  const aosJson = aosJsonFile
    const initializeApp = async () => {
      try {
        console.log("---------------------------> INIT")
        let webAosConfig = aosJson;
        webAosConfig = { ...webAosConfig, 'projectName': "Norqain", 'projectVersion': "0.0.1"};
        webAos.start(setQrUrl, setQrScanned, setWebRTCReady, webAosConfig);

        return () => {
        };
      } catch (error) {
        console.log("Initialization failed:", error);
      }
    };

    initializeApp();
  }
  }, [scenario]);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    if (queryParams.has("scenarioid")) {
      const ScenarioUUID = queryParams.get("scenarioid");
      console.log("Request Scenario:", ScenarioUUID);
      InitBackconn(setIsReady, setLandingVideoURL, setQRSize, setLeftOffest, setTopOffset, setMedias, setObject3D, setScenario, ScenarioUUID);
    }
  }, []);

  useEffect(() => {
    if (IsReady) setPageToDisplay("landing");
  }, [IsReady]);

  useEffect(() => {
    if (qrScanned){
      setPageToDisplay("experience");
    }else{
      setPageToDisplay("landing");
      setCountdown(null);
    }
  }, [qrScanned]);


  useEffect(() => {
    if(qrScanned){
    let lastMessageTime = Date.now();

    const handleReset = () => {
      messagesEmitter.emit("error", new Error(`WebRTC connection disconnected`));
    };

    const updateLastMessageTime = () => {
      lastMessageTime = Date.now();
      setCountdown(null); // Réinitialiser le compte à rebours lorsqu'un message est reçu
    };

    messagesEmitter.on("set_choices", updateLastMessageTime);
    //messagesEmitter.on("zoom", updateLastMessageTime);
    messagesEmitter.on("move", updateLastMessageTime);


    const intervalId = setInterval(() => {
      const timeElapsed = Date.now() - lastMessageTime;
      if (timeElapsed > 120000) { // 2 minutes = 120000 millisecondes
        handleReset();
      } else if (timeElapsed > 90000) { // Plus de 1 minute et 30 secondes se sont écoulées
        // Mettre à jour le compte à rebours toutes les secondes
        const secondsLeft = Math.round((120000 - timeElapsed) / 1000);
        setCountdown(secondsLeft);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }
  }, [qrScanned]);


 const handlePad = () => {
  console.log("EWRGTaewrga")
 if(object3D === null){
messagesEmitter.emit("set_component_visibility", "pad", false);
messagesEmitter.emit("set_component_visibility", "zoom", false);
}
 }

 useEffect(() => {
  if (webRTCReady) handlePad()
}, [webRTCReady]);

useEffect(() => {
  console.log(qrUrl);
}, [qrUrl]);


  return (
    <div style={{overflow: "hidden"}}>
      <div style={{width: "100%", paddingTop: "2%", position: "absolute"}}>
      {countdown !== null && (
          <p style={{textAlign: "center", width: "100%", color: "white"}}>Inactivity : You'll be disconnected in {countdown} seconds</p>
        )}
      </div>
    {PageToDisplay === "loader" &&
      <h1>Loading...</h1>
    }
    {PageToDisplay === "landing" &&
    <Landing qrUrl={qrUrl} LandingVideoURL={LandingVideoURL} QRSize={QRSize} leftOffset={leftOffset} topOffset={topOffset}/>
    }
      {PageToDisplay === "experience" && !object3D &&
        <Experiences Medias={Medias} />
      }
      {PageToDisplay === "experience" && object3D &&
        <Experience3D object3D={object3D} />
      }
    </div> 
  );
};

export default App;