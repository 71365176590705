import React, { useEffect, useState } from 'react';
import "./Experience.css";
import messagesEmitter from '../webAos/handlers/messagesEmitters';
//const backendconfig = require("../BackendConfig.json");
const backendconfig = {domain: window.location.origin + window.location.pathname};

const Experience = (props) => {
    const { Medias, isNavigationEnabled } = props;
    const [CurrentMedia, setCurrentMedia] = useState(0);
    const [videoElements, setVideoElements] = useState([]);
    const [loading, setLoading] = useState(true); // Ajout de l'état loading

    useEffect(() => {
        // Précharger les vidéos
        let videosLoaded = 0;
        const loadedVideos = Medias.map(media => {
            const video = document.createElement('video');
            video.src = `${backendconfig.domain}backend/uploads/${media.url}`;
            video.preload = 'auto';
            video.oncanplaythrough = () => { // Écouteur d'événement pour le chargement
                videosLoaded++;
                if (videosLoaded === Medias.length) {
                    setLoading(false); // Mettre à jour l'état lorsque toutes les vidéos sont chargées
                }
            };
            return video;
        });
        setVideoElements(loadedVideos);
    }, [Medias]);

    useEffect(() => {
        console.log(CurrentMedia);
        console.log(Medias.length);
    }, [CurrentMedia]);

    const navigateToMediaById = (aos_optionid) => {
        const mediaIndex = Medias.findIndex(media => media.aos_optionid === aos_optionid);
        if(mediaIndex !== -1) {
            setCurrentMedia(mediaIndex);
        }
    };

    useEffect(() => {
        const handleButtonPress = (args) => {
            console.log(args);
            navigateToMediaById(args.Montres)
        };

        messagesEmitter.on("set_choices", handleButtonPress);
        // Cleanup on unmount
        //return () => messagesEmitter.off("set_choices", handleButtonPress);
    }, []);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (!isNavigationEnabled) return;
            if (event.key === 'ArrowRight') {
                if (CurrentMedia + 1 < Medias.length) {
                    setCurrentMedia(prevCurrentMedia => prevCurrentMedia + 1);
                }
            }
            if (event.key === 'ArrowLeft') {
                if (CurrentMedia - 1 >= 0) {
                    setCurrentMedia(prevCurrentMedia => prevCurrentMedia - 1);
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [CurrentMedia, isNavigationEnabled]);

    return (
        <div id='experiencecontain'>
            {loading && <div className="loader">Chargement...</div>}
            {!loading &&
            <video autoPlay muted loop ref={element => {
                if(element) {
                    element.src = videoElements[CurrentMedia]?.src || '';
                }
            }}></video>
            }
        </div>
    );
}

export default Experience;
