import { initializeApp } from 'firebase/app';
import { getDatabase, ref, update, child } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

let firebaseConfig;
let dbRef;
let computerName;
let user_email;
let user_password;
let signedInFirebase = false;
let auth;
let projectName;


class LogFirebase {
  constructor(fullConfig, project) {
    firebaseConfig = {
      apiKey: fullConfig.apiKey,
      authDomain: fullConfig.authDomain,
      databaseURL: fullConfig.databaseURL,
      projectId: fullConfig.projectId,
      storageBucket: fullConfig.storageBucket,
      messagingSenderId: fullConfig.messagingSenderId,
      appId: fullConfig.appId,
    };
    user_email = fullConfig.email;
    user_password = fullConfig.password;
    projectName = project;
    const url_protocol = window.location.protocol.replace(/:/g,'');
    let url_port = "0";
    if(url_protocol === "https") {
      url_port = "443";
    }
    else if(url_protocol === "http") {
      url_port = "80";
    }
    computerName = window.location.host.replace(/:/g,'-').split('.').join('') + window.location.pathname.replace(/\//g,'-').replace(/\-+$/, '')+"_"+url_port; 
  }

  async init() {
    const app = await initializeApp(firebaseConfig);
    auth = await getAuth(app);
    dbRef = await ref(getDatabase());

    onAuthStateChanged(auth, (user) => {
      if (user) {
        signedInFirebase = true;
      } else {
        // User is signed out
        signedInFirebase = false;
      }
    });
  }

  async writeStatus(message) {
    const date = new Date();
    const data = {
      'nodejsServerTimestampMessage': date.getTime(),
      'nodejsServerMessage': message
    };
    await this.updateDatabase(`computers/${computerName}`, data, 'Firebase writeStatus');
  }

  async writeInit(webAosVersion, projectVersion) {
    const date = new Date();
    const data = {
      'nodejsServerStarted': date.getTime(),
      'nodejsServerVersion': webAosVersion,
      'projectName': projectName,
      'projectVersion': projectVersion
    };
    await this.updateDatabase(`computers/${computerName}`, data, 'Firebase writeInit');
  }

  async writeLog(message, logType, source) {
    const date = new Date();
    const data = {
      'logType' : logType,
      'message': message,
      'source': "webAos"
    };
    if (source) {
      data.source = source;
      await this.updateDatabase(`logs_app/${computerName}/${date.getTime()}`, data, 'Firebase writeLog');
    }
    else {
      await this.updateDatabase(`logs_nodeJS/${computerName}/${date.getTime()}`, data, 'Firebase writeLog');
    }
  }

  async writeConnection(connectionJson, connectName) {
    connectionJson = { ...connectionJson, 'computer': computerName};
    const connectionName = "connection_" + connectName;
    await this.updateDatabase(`connections/${projectName}/${connectionName}`, connectionJson, 'Firebase writeConnection');
  }

  async writeAction(actionJson, connectName) {
    const date = new Date();
    const connectionName = "connection_" + connectName;
    await this.updateDatabase(`actions/${projectName}/${connectionName}/${date.getTime()}`, actionJson, 'Firebase writeAction');
  }

  async signInIfNeeded() {
    if (!signedInFirebase) {
      signInWithEmailAndPassword(auth, user_email, user_password)
      .then((userCredential) => {
        signedInFirebase = true;
      })
      .catch((error) => {
        console.log(`Firebase sign-in error: ${error.message}`);
      });
    }
  }

  async updateDatabase(path, data, errorMessage) {
    try {
      await this.signInIfNeeded();
      await update(child(dbRef, path), data);
    } catch (error) {
      console.log(`${error.message} error: ${error.code}`);
    }
  }
}

export default LogFirebase;
