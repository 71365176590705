import messagesEmitter from "../messagesEmitters";
import {logger} from "../logger";

let actionJson = {
    type: "",
    value: ""
}

class DatabaseActions {
  constructor() {
	  messagesEmitter.on("remoteAction", (json, connectionName) => {
	  /*
      switch (json.cmd) {
        case "BTN_PRESSED":
          actionJson.type = json.cmd;
          actionJson.value = json.args.btnId;
          logger.writeAction(actionJson, connectionName);
          break;
        case "SET_CHOICES":
          cmd.args.forEach((keyPairs) => {
            actionJson.type = json.cmd;
            actionJson.value = keyPairs.Key + "_" + keyPairs.Value; 
            logger.writeAction(actionJson, connectionName);           
          });
          break;
        case "SET_MULTICHOICE":
          cmd.args.forEach((keyPairs) => {
            actionJson.type = json.cmd;
            actionJson.value = keyPairs.Key + "_" + keyPairs.Value;  
            logger.writeAction(actionJson, connectionName);          
          });
          break;
      }
      */
	  });
  }
}

export default DatabaseActions;
